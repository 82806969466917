import Swal from "sweetalert2";
export const isStoreOpen = () => {
  // Get the current date and time in the Mountain Time Zone
  const currentDate = new Date().toLocaleString("en-US", {
    timeZone: "America/Denver", // Set the time zone to Mountain Time Zone
  });

  // Extract the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const currentDayOfWeek = new Date(currentDate).getDay();

  // If it's Tuesday, the store is closed
  // if (currentDayOfWeek === 2) {
  //   return false;
  // }

  // Extract the current time in HH:mm format
  const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
    hour12: false, // Use 24-hour format
    hour: "2-digit",
    minute: "2-digit",
  });

  // Define the store's opening hours in Mountain Time Zone
  const openingHours = [
    { day: 1, start: "15:00", end: "21:45" }, // Monday
    { day: 3, start: "15:00", end: "21:45" }, // Wednesday
    { day: 4, start: "15:00", end: "21:45" }, // Thursday
    { day: 5, start: "15:00", end: "21:45" }, // Friday
    { day: 6, start: "15:00", end: "21:45" }, // Saturday
    { day: 0, start: "15:00", end: "21:45" }, // Sunday
  ];

  // Check if the store is open based on today's schedule
  return openingHours.some((hour) => {
    if (hour.day === currentDayOfWeek) {
      const startTime = new Date(`2022-01-01T${hour.start}:00`);
      const endTime = new Date(`2022-01-01T${hour.end}:00`);
      const currentTimeFormatted = new Date(`2022-01-01T${currentTime}:00`);
      return currentTimeFormatted >= startTime && currentTimeFormatted <= endTime;
    }
    return false;
  });
};


  export const checkIfLunchTime = () => {
    // Get the current date and time in the Eastern Time Zone
    const currentDate = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
    });

    // Extract the current time in HH:mm format
    const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
        hour12: false, // Use 24-hour format
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
    });

    // Define the store's opening hours
    const openingHours = { start: "15:00", end: "17:00" };

    // Extract hours and minutes from current time
    const [currentHour, currentMinute] = currentTime.split(":").map(Number);

    // Extract hours and minutes from opening hours
    const [startHour, startMinute] = openingHours.start.split(":").map(Number);
    const [endHour, endMinute] = openingHours.end.split(":").map(Number);

    // Check if current time is within lunch hours
    const isLunchTime =
        (currentHour > startHour || (currentHour === startHour && currentMinute >= startMinute)) &&
        (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute));

    return isLunchTime;
};


export const showTimeError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Please come back later.',
      text: 'Hours: Mon-Sat 11:00 - 9:30PM, Sun: 12:00 - 9:30PM',

    })
  }
